<template lang="pug">
  v-dialog(
    width="800"
    v-model="open"
    persistent
  )
    v-card
      v-toolbar(
        color="yellow darken-3"
        dense
        flat
      )
        span Transfer Rack No:
        span.ml-2 {{ value.code }}
      v-container
        v-row
          v-col(cols="12")
            v-autocomplete(
              label="Transfer to"
              v-model="incubatorRackId"
              :items="incubatorRackList"
              :search-input.sync="incubatorRackListSearch"
              item-value="id"
              dense
              item-text="code"
              no-filter
              :loading="incubatorRackGetting"
              :error-messages="trasferRackEggPostErrors.incubator_rack_id"
            )
      v-card-actions
        v-btn(
          text
          color="green"
          dense
          @click="transfer"
          :loading="trasferRackEggPosting"
        )
          span Transfer
        v-btn(
          text
          color="red"
          dense
          @click="open = false"
        )
          span Close
</template>
<script>
import incubatorRepository from '@/repositories/incubator.repository'
import rackEggRepository from '@/repositories/rack-egg.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [incubatorRackVars, incubatorRackVarNames] = requestVars({ identifier: 'incubator-rack' })
const [transferRackEggVars, transferRackEggVarNames] = requestVars({ identifier: 'trasfer-rack-egg', request: 'post', hasData: false })

const incubatorRackHandler = new VueRequestHandler(null, incubatorRackVarNames)
const transferRackEggHandler = new VueRequestHandler(null, transferRackEggVarNames)

const inputVars = () => ({
  incubatorRackId: null,
})

export default {
  name: 'TransferIncubatorRack',
  props: {
    value: Object,
  },
  data () {
    return {
      ...inputVars(),
      ...transferRackEggVars,
      ...incubatorRackVars,
      incubatorRackListSearch: null,
    }
  },
  computed: {
    transferTo () {
      return this.incubatorRackList.find(item => item.id === this.incubatorRackId) || {}
    },
    open: {
      get () {
        return !this.$objectEmpty(this.value)
      },
      set (val) {
        if (val) return
        this.$emit('input', {})
      },
    },
  },
  watch: {
    open (val) {
      if (!val) return
      this.getRacks()
    },
    incubatorRackListSearch () {
      this.getRacks()
    },
  },
  methods: {
    async transfer () {
      const warningMessage = `are you sure you want to trasfer from ${this.value.code} to ${this.transferTo.code}`
      const confirm = await this.$confirm(warningMessage)
      if (!confirm) return
      const handler = transferRackEggHandler
      const repository = rackEggRepository.transfer
      const { current = {} } = this.value
      const id = current.rack_egg_id
      const data = this.$getInputData(inputVars())
      handler.setVue(this)
      handler.execute(repository, [id, data])
        .then(() => {
          this.$emit('success')
          this.$resetInputData(inputVars())
          this.open = false
        })
    },
    getRacks () {
      const handler = incubatorRackHandler
      const repository = incubatorRepository.racks
      const params = { search: this.incubatorRackListSearch }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
  },
}
</script>